<template>
  <div>
    <div
      class="mx-auto max-w-xl rounded-3xl bg-primary p-5 text-white sm:p-10"
      id="success-element"
    >
      <h1 class="mb-3 text-3xl font-bold text-white">{{ title }}</h1>
      <div class="prose text-lg text-white">
        <p>{{ message }}</p>
        <div v-if="success">
          <p class="text-white">{{ feedbackQuestion }}</p>
          <TrustPilotStatic />
          <p>{{ thankYouMessage }}</p>
        </div>
      </div>
    </div>
    <div class="mt-8 flex flex-row items-center justify-center">
      <button
        @click="redirectToRoot"
        class="btn flex flex-row items-center justify-center gap-x-3 text-xl underline"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, watch, ref } from 'vue'
import TrustPilotStatic from '@/components/Elements/Trustpilot/TrustpilotStatic.vue'

const props = defineProps({
  title: {
    type: String,
    default: 'Vielen Dank für Ihre Anfrage!'
  },
  message: {
    type: String,
    default: 'Wir werden uns kurzfristig bei Ihnen melden, um alles weitere zu besprechen.'
  },
  success: {
    type: Boolean,
    default: false
  },
  feedbackQuestion: {
    type: String,
    default: 'Wie hat Ihnen der Sanierungsrechner gefallen?'
  },
  thankYouMessage: {
    type: String,
    default: 'Vielen Dank für Ihr Vertrauen und viel Erfolg bei Ihrer anstehenden Sanierung.'
  },
  buttonText: {
    type: String,
    default: 'Zurück zur Startseite'
  },
  response: {
    type: Object,
    default: () => ({})
  }
})

const redirectToRoot = () => {
  window.location.href = '/'
}

let adcellScript = null

const addAdcellScript = () => {
  // Check if the script is already added
  if (adcellScript) {
    console.log('Adcell script already added.')
    return
  }

  // Safely access nested properties
  const referenzId = props.response?.data?.id
  if (!referenzId) {
    console.warn('Cannot add Adcell script: response.data.id is missing')
    return
  }

  try {
    adcellScript = document.createElement('script')
    adcellScript.type = 'text/javascript'
    adcellScript.async = true
    adcellScript.src = `https://t.adcell.com/t/track.js?pid=13987&eventid=17870&referenz=${encodeURIComponent(referenzId)}`

    // Add error handling
    adcellScript.onerror = (error) => {
      console.error('Failed to load Adcell script:', error)
    }

    document.body.appendChild(adcellScript)
    console.log('Adcell tracking script added with referenz:', referenzId)
  } catch (error) {
    console.error('Error adding Adcell script:', error)
  }
}

// Lifecycle hooks
onMounted(() => {
  if (props.success) {
    addAdcellScript()
  }
})

// Watch for success changes
watch(
  () => props.success,
  (newVal) => {
    if (newVal) {
      addAdcellScript()
    }
  }
)
</script>

<style scoped>
/* Add your styles here if necessary */
</style>
