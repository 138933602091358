<template>
  <div>
    <!-- Mobile/Tablet View (up to 2xl breakpoint) -->

    <div
      class="mx-auto mb-24 mt-40 flex max-w-3xl flex-col justify-end gap-12 border-t pt-4 sm:mt-60"
    >
      <div class="mx-auto grid grid-cols-1 gap-10 sm:gap-32 lg:grid-cols-3">
        <div v-for="bullet in bullets" :key="bullet.text" class="flex items-center justify-center">
          <div class="flex w-full flex-row items-center gap-md">
            <div class="rotate-45 rounded-md border border-primary bg-primary">
              <component :is="bullet.icon" class="size-8 -rotate-45 text-action" />
            </div>
            <p class="text-xs">{{ bullet.text }}</p>
          </div>
        </div>
      </div>
      <div className=" border-t py-8">
        <Brands />
      </div>
      <div class="flex flex-row flex-wrap items-center justify-center gap-7 text-sm text-gray-400">
        <a href="#" @click.prevent="openWidget">Cookie-Einstellungen</a>
        <a href="https://moreathome.de/datenschutz" target="_blank">Datenschutzerklärung </a>
        <a href="https://moreathome.de/impressum" target="_blank">Impressum </a>
      </div>
    </div>
    <!-- Desktop View (2xl and above) DEACTIVATED! -->
    <div class="fixed bottom-0 right-0 z-50 hidden p-4">
      <div class="flex flex-col items-end text-sm">
        <div class="flex flex-row items-center justify-between gap-5">
          <a href="#" @click.prevent="openWidget"> Cookie-Einstellungen </a>
          <a href="https://moreathome.de/datenschutz" target="_blank"> Datenschutzerklärung </a>
          <a href="https://moreathome.de/impressum" target="_blank"> Impressum </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DollarIcon from '@public/images/dollar.svg'
import NetworkIcon from '@public/images/network.svg'
import ContactIcon from '@public/images/contact.svg'
import Brands from '@/components/Icons/Brands.vue'

const openWidget = (e) => {
  e.preventDefault()
  CCM.openWidget()
  return false
}

const bullets = [
  {
    icon: DollarIcon,
    text: '100% kostenlos und unverbindlich'
  },
  {
    icon: NetworkIcon,
    text: 'Schnell und unkompliziert'
  },
  {
    icon: ContactIcon,
    text: 'Kostenlose Beratung'
  }
]
</script>
