<script setup>
import SummaryBar from '@/components/Structure/SummaryBar.vue'
import { computed, onMounted, ref, watch, onBeforeUnmount } from 'vue'
import services from '@/data/services'
import ContactPanel from '@/components/Structure/Singles/ContactPanel.vue'
import Header from '@/components/Structure/Header.vue'
import { useLocalStorage } from '@vueuse/core'
import useEmitter from '@/composables/useEmitter.js'
import track, { gaServiceTrack } from '@/utils/tracking.js'
import {
  availableSteps,
  initials,
  registerShortcuts,
  registerWatcher,
  totalInitials
} from '@/utils/calculators.js'

import { findServiceTotal, getServiceOfStep } from '@/utils/steps.js'

import SidebarMenu from '@/components/Navigation/v2/SideBarMenu.vue'
import { getGclid, getUtmParams } from '@/utils/gclid'
import ServiceConfiguration from '@/components/Navigation/v2/ServiceConfiguration.vue'
import FinalStep from '@/components/Structure/FinalStep.vue'
import { serviceConfigs } from '@/utils/serviceConfigs.js'
import FooterLinks from '@/components/Structure/V2/FooterLinks.vue'

const API_URL = import.meta.env.VITE_API_URL

const emitter = useEmitter()
const enabled = ref(false)

const loading = ref(false)
const submitting = ref(false)
const submitted = ref(false)
const responseObject = ref()

const props = defineProps({
  isProject: {
    type: Boolean,
    default: false
  },
  isInternal: {
    type: Boolean,
    default: false
  },
  isLv: {
    type: Boolean,
    default: false
  },
  services: {
    type: String,
    default: ''
  }
})

const json = (i) => JSON.parse(JSON.stringify(i))

const formData = useLocalStorage('form', json(initials))

const totals = useLocalStorage('totals', json(totalInitials))

onMounted(async () => {
  let anyServiceEnabled = false

  // Loop through services
  for (const service in formData.value.services) {
    if (formData.value.services[service].enabled) {
      anyServiceEnabled = true
      break // Exit the loop early if any service is enabled
    }
  }

  if (!anyServiceEnabled) {
    formData.value.step = 'types'
  }

  emitter.on('calc:add-room', () => {
    formData.value.step = 'start'
  })

  if (formData.value.version && formData.value.version !== initials.version) {
    resetAllServices()
    enabled.value = true
  } else {
    enabled.value = true
  }
  /**/
  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'sanitation',
        content_name: 'sanitation'
      }
    ]
  })
  track.tmViewItem('sanitation')

  updateServices(formData.value.services)
  document.addEventListener('keydown', preventEnterKeyDefault)

  // Check if 'type' exists in the query parameters
  const urlParams = new URLSearchParams(window.location.search)
  const typeFromQuery = urlParams.get('type')

  if (typeFromQuery && ['apartment', 'house'].includes(typeFromQuery)) {
    // If the type from query doesn't match formData.value.base.type, reset everything
    if (typeFromQuery !== formData.value.base.type) {
      resetAllServices()
    }
    formData.value.base.type = typeFromQuery
  }

  if (urlParams.get('zip')) {
    formData.value.base.postal_code = urlParams.get('zip')
  }

  if (urlParams.get('s')) {
    const parts = urlParams.get('s').split(',')
    const allowed = Object.keys(services)

    parts.forEach((part) => {
      if (allowed.includes(part)) {
        formData.value.services[part].enabled = true
      }
    })
  }
})

const endpoint = ref(props.isLv ? '/api/forms/doc' : '/api/forms/sc')

const submitHandler = async (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  loading.value = true
  submitting.value = true

  setTimeout(() => {
    loading.value = false
    submitted.value = true
  }, 3000)

  formData.value.contact.gclid = getGclid()
  formData.value.utm = getUtmParams()

  try {
    console.log('formData.value', endpoint.value, formData.value)
    if (props.isLv) {
      formData.value.contact.phone = '00000000'
    }
    const response = await fetch(API_URL + endpoint.value, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(formData.value)
    })

    if (!response.ok && response.status !== 301) {
      const errorData = await response.json()
      responseObject.value = {
        error: true,
        message: errorData.message || 'An error occurred'
      }
      return
    }
    const data = await response.json()
    responseObject.value = data
    if (response.status === 301) {
      window.location.href = data.url
      return
    }

    submitted.value = true
    track.fast(data)
    track.yandex(97558574, 'reachGoal', 'form-click')
    track.tiktok(
      'SubmitForm',
      {
        contents: [
          {
            content_id: 'sanitation',
            content_name: 'sanitation'
          }
        ],
        value: data.volume / 100,
        currency: 'EUR'
      },
      {
        email: formData.value.contact.email,
        external_id: data.id
      }
    )
    track.tmPurchase('sanitation', data.volume / 100, 'EUR', data.id, formData.value.contact)

    return data
  } catch (error) {
    responseObject.value = {
      error: true,
      message: 'An error occurred'
    }
    throw error // Re-throw the error for the caller to handle if needed
  } finally {
    submitting.value = false
  }
}

registerWatcher(formData, totals)
registerShortcuts(formData, totals)

const enabledServices = computed(() => {
  return Object.keys(formData.value.services).filter(
    (service) => formData.value.services[service].enabled
  )
})

const lastServiceStep = computed(() => {
  const lastService = enabledServices.value[enabledServices.value.length - 1]
  return lastService ? `services_${lastService}_start` : ''
})

function updateServices(services) {
  for (const service in services) {
    const step = availableSteps.find((step) => step.title === `services_${service}_start`)

    // Update formData
    if (!formData.value.services[service]) {
      formData.value.services[service] = {}
    }

    formData.value.services[service].enabled = services[service].enabled
    formData.value.services[service].completed = services[service].completed

    if (services[service].enabled) {
      if (step) step.enabled = true
      if (services[service].completed) {
        step.completed = true
      }
    } else {
      if (step) {
        step.enabled = false
        step.completed = false
      }
    }
  }
}

watch(
  () => formData.value.services,
  (services) => {
    updateServices(services)
  },
  { deep: true }
)

watch(
  () => formData.value.step,
  (step) => {
    if (step && step.includes('final')) {
      const service = getServiceOfStep(step)
      if (service) {
        const currentStep = availableSteps.find((s) => s.title && s.title.includes(service))

        if (currentStep && currentStep.totals_key) {
          const totals_service = findServiceTotal(totals.value, currentStep.totals_key)

          if (totals_service && formData.value.services && formData.value.services[service]) {
            formData.value.services[service].completed = true
            gaServiceTrack(service, totals_service)
          }
        }
      }
    }
    // Scroll to top when formData.value.step changes
    window.scrollTo(0, 0)
  },
  { immediate: true },
  { deep: true }
)

const isCurrentStepInTotals = computed(() => {
  try {
    const key = getCurrentStepTotalsKey()
    return totals.value.services?.some((service) => service.name === key) || false
  } catch (error) {
    console.error('Error in isCurrentStepInTotals computed property:', error)
    return false
  }
})
const resetService = () => {
  const service = formData.value.step.split('_')[1]
  formData.value.step = `services_${service}_start`
}

const getCurrentStepTotalsKey = () => {
  const service = formData.value.step.split('_')[1]
  const currentStep = availableSteps.find((step) => step.title.includes(service))
  return currentStep ? currentStep.totals_key : null
}

const filteredServices = computed(() => {
  return Object.values(
    Object.fromEntries(
      Object.entries(availableSteps).filter(([_, service]) =>
        service.forTypes.includes(formData.value.base.type)
      )
    )
  )
})
const resetAllServices = () => {
  // Reset serviceConfigs
  Object.keys(serviceConfigs).forEach((service) => {
    const localStorageKey = `${service}_service_model`
    localStorage.setItem(localStorageKey, JSON.stringify(json(serviceConfigs[service])))
  })

  // Reset formData.value
  formData.value = {
    ...json(initials), // Assuming 'initials' is the initial state of the formData
    services: Object.keys(services).reduce((acc, service) => {
      acc[service] = JSON.parse(JSON.stringify(services[service]))
      return acc
    }, {})
  }

  // Reset rooms
  formData.value.rooms = []

  // Reset active step
  formData.value.step = 'types'
}

const preventEnterKeyDefault = (event) => {
  if (
    event.key === 'Enter' &&
    event.target.tagName === 'INPUT' &&
    !event.target.classList.contains('allow-enter')
  ) {
    event.preventDefault()
  }
}

onMounted(() => {
  // Get services by query in URL and activate each service

  const urlParams = new URLSearchParams(window.location.search)
  const services = urlParams.get('services')
  const reset = urlParams.get('reset')

  if (reset) {
    return
  }

  if (services) {
    // Split the services string into an array
    resetAllServices()
    const serviceArray = services.split(',')

    // Activate each service in the array
    serviceArray.forEach((service) => {
      const serviceKey = service.trim()
      if (formData.value.services[serviceKey]) {
        formData.value.services[serviceKey].enabled = true
      }
    })
    updateServices(formData.value.services)
    urlParams.set('reset', 'true')

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`
    window.history.replaceState({}, '', newUrl)
  }

  if (props.services) {
    resetAllServices()
    const serviceArray = props.services.split(',')
    serviceArray.forEach((service) => {
      const serviceKey = service.trim()
      if (formData.value.services[serviceKey]) {
        formData.value.services[serviceKey].enabled = true
      }
    })
    updateServices(formData.value.services)
    urlParams.set('reset', 'true')

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`
    window.history.replaceState({}, '', newUrl)
  }
})
</script>

<template>
  <Transition>
    <div>
      <form
        @submit.prevent="submitHandler"
        class="mt-14 max-w-[1700px] text-primary md:mx-10 md:mt-[75px] xl:mx-auto xl:mt-[75px]"
        v-if="enabled"
        id="mah-wrapper"
      >
        <Header />
        <SidebarMenu
          v-model="formData"
          :options="filteredServices"
          :totals="totals"
          :isLv="isLv"
          v-if="formData.step !== 'services' && formData.step !== 'types' && !submitted && !loading"
        />

        <div
          class="mx-auto max-w-[1400px] md:max-w-3xl lg:max-w-[1500px] xl:max-w-4xl"
          v-if="!submitted && !loading"
        >
          <div class="mx-auto mb-20 max-w-3xl">
            <slot v-if="formData.step === 'types'"></slot>
            <ServiceConfiguration
              v-model="formData"
              :totals="totals"
              :enabledServices="enabledServices"
              :availableSteps="availableSteps"
              :reset-services="resetAllServices"
            />
            <div v-if="$slots.default && formData.step === 'types'"></div>
          </div>
          <FinalStep
            v-if="formData.step.includes('final')"
            :isLv="isLv"
            :formData="formData"
            :totals="totals"
            :getCurrentStepTotalsKey="getCurrentStepTotalsKey"
            :isCurrentStepInTotals="isCurrentStepInTotals"
            :enabledServices="enabledServices"
            :filteredServices="filteredServices"
            @resetService="resetService"
            :available-steps="availableSteps"
          />
        </div>

        <SummaryBar
          v-if="formData.step !== 'services' && formData.step !== 'types' && !submitted && !loading"
          v-model="formData"
          :totals="totals"
          :is-project="isProject"
          :is-internal="isInternal"
          :options="filteredServices"
        />
        <ContactPanel
          v-model="formData"
          :totals="totals"
          :options="filteredServices"
          :submitted="submitted"
          :submitting="submitting"
          :loading="loading"
          :isLv="isLv"
          :response="responseObject"
          v-if="
            formData.step === 'contact' ||
            formData.step === 'contractor' ||
            formData.step === 'contactForm'
          "
          :lastStep="lastServiceStep"
        />
      </form>
      <FooterLinks />
    </div>
  </Transition>
</template>
