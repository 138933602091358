<script setup>
import { computed, nextTick, onMounted, ref, triggerRef, watch } from 'vue'
import IconImporter from '@/components/Elements/IconImporter.vue'
import AnimatedNumber from '@/components/Inputs/AnimatedNumber.vue'
import { CheckIcon } from '@heroicons/vue/24/solid/index.js'
import { findServiceTotal } from '@/utils/steps.js'
import { sliceBeforeThirdUnderscore } from '@/utils/steps.js'
import { configTemplate as wallsConfig } from '@/data/options/v2/walls.js'
import { configTemplate as floorConfig } from '@/data/options/v2/floor.js'
import { configTemplate as bathConfig } from '@/data/options/v2/baths.js'
import services from '@/data/services.js'
import { serviceConfigs } from '@/utils/serviceConfigs.js'
import { initials } from '@/utils/calculators.js'

const props = defineProps({
  totals: Object,
  modelValue: Object,
  options: {
    type: Array,
    required: true
  }
})

const contactStep = {
  title: 'contactForm',
  value: 'contactForm',
  enabled: false
}

const emit = defineEmits(['update:modelValue', 'update:totals'])

const totals = computed({
  get: () => props.totals,
  set: (v) => emit('update:totals', v)
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const activeStep = ref(model.value.step || 'services')
const isExpanded = ref(false)
const error = ref(false)
const json = (i) => JSON.parse(JSON.stringify(i))

const setActiveStep = (step) => {
  if (step === 'contactForm') {
    activeStep.value = 'contactForm'
    model.value = {
      ...model.value,
      step: 'contactForm'
    }
    return
  }
  if (!step.enabled) {
  }
  activeStep.value = step.title

  // Extract the service name from step.title
  const currentService = step.title.split('_')[1]

  // Check if the service exists in model.value.services
  if (model.value.services && model.value.services[currentService]) {
    model.value.services[currentService].enabled = true
  }
  model.value.step = step.title

  nextTick(() => {
    model.value = {
      ...model.value,
      step: step.title
    }
  })
  emit('stepActivated', step.title)
}

const deactivateStep = (step) => {
  const stepTitle = step.title
  const currentService = stepTitle.split('_')[1]
  const isCurrentStep = activeStep.value.includes(currentService)

  // Set localStorage for specific services
  if (currentService in serviceConfigs) {
    // Get the current data from localStorage
    const service = model.value.services[currentService]
    const roomIdsToRemove = service.roomIds || []

    // Remove rooms that match any ID in roomIdsToRemove
    if (model.value.rooms && roomIdsToRemove.length > 0) {
      model.value.rooms = model.value.rooms.filter((room) => {
        const roomId = typeof room === 'object' ? room.id : room
        return !roomIdsToRemove.includes(roomId)
      })
    }
  }

  // Create a new services object and disable the current service
  const updatedServices = {
    ...model.value.services,
    [currentService]: {
      ...model.value.services[currentService],
      enabled: false
    }
  }

  // Check if no services are enabled
  const noEnabledServices = !Object.values(updatedServices).some((service) => service.enabled)

  // Update active step
  if (isCurrentStep) {
    const currentIndex = props.options.findIndex((s) => s.title === stepTitle)
    const nextActiveStep = findNextActiveStep(currentIndex)
    activeStep.value = nextActiveStep ? nextActiveStep.title : 'services'
  }

  // Set active step to 'services' if no services are enabled
  if (noEnabledServices) {
    activeStep.value = 'services'
  }

  // Reset the current service to its default value
  updatedServices[currentService] = JSON.parse(JSON.stringify(services[currentService]))

  // Update the entire model
  model.value = {
    ...model.value,
    step: isCurrentStep ? activeStep.value : model.value.step,
    services: updatedServices
  }
}

// Helper function to find the next active step
const findNextActiveStep = (currentIndex) => {
  for (let i = currentIndex + 1; i < props.options.length; i++) {
    if (props.options[i].enabled) {
      return props.options[i]
    }
  }
  // If no next step is found, wrap around to the beginning
  for (let i = 0; i < currentIndex; i++) {
    if (props.options[i].enabled) {
      return props.options[i]
    }
  }
  return null
}

const isActive = (step) => {
  const slicedStep = sliceBeforeThirdUnderscore(step.title)
  const activeStepSliced = sliceBeforeThirdUnderscore(activeStep.value)
  return activeStepSliced === slicedStep
}

const isSubmitReady = ref(false)
// check if all enabled steps from model.value.services which are enabled are also completed in a function
const checkIfSubmitReady = () => {
  const enabledServices = Object.keys(model.value.services)
    .filter((service) => service !== 'contractor')
    .filter((service) => model.value.services[service].enabled)

  const completedServices = enabledServices.filter(
    (service) => model.value.services[service].completed
  )

  isSubmitReady.value = enabledServices.length === completedServices.length
}

const contactFormButton = async () => {
  checkIfSubmitReady()
  if (!isSubmitReady.value) {
    error.value = true
    setTimeout(() => {
      error.value = false
    }, 1000) // Reset after 1 second
  } else if (isSubmitReady.value === true) {
    setActiveStep(contactStep)
  }
}

watch(
  () => model.value.step,
  () => {
    checkIfSubmitReady()
  },
  { deep: true }
)

watch(
  () => model.value.services,
  () => {
    checkIfSubmitReady()
  },
  { deep: true }
)

watch(
  () => props.modelValue?.step,
  (newStep) => {
    if (newStep) {
      activeStep.value = newStep
    }
  },
  { deep: true }
)

watch(activeStep, (newActiveStep) => {
  model.value.step = newActiveStep // Sync model with activeStep
})

const checkServiceValue = () => {
  if (model && model.value.services) {
    Object.entries(model.value.services).forEach(([key, service]) => {
      if (service.enabled) {
        if (props.options && Array.isArray(props.options)) {
          const option = props.options.find((option) => option.value === key)
          if (option && option.totals_key) {
            const total = findServiceTotal(props.totals, option.totals_key)
            if (!total) {
              service.completed = false
              option.completed = false
              return false
            } else {
              service.completed = true
              option.completed = true
              return true
            }
          }
        }
      }
    })
  }
  checkIfSubmitReady()
}

watch(
  () => props.totals,
  () => {
    checkServiceValue()
    checkIfSubmitReady()
  }
)

onMounted(() => {
  checkIfSubmitReady()
  checkServiceValue()
})
</script>

<template>
  <nav class="z-40 bg-primary shadow-lg transition-all duration-300 ease-in-out ipad:hidden">
    <ul class="">
      <li
        v-for="step in options"
        :key="step.title"
        class="relative flex cursor-pointer items-center gap-8 overflow-hidden whitespace-nowrap px-2 py-3 text-gray-300"
        :class="{
          'border-l-2': isActive(step),
          'hover:bg-primary-light': !isActive(step),
          'text-action': step.enabled
        }"
        @click="() => setActiveStep(step)"
      >
        <IconImporter
          class="flex-shrink-0 rounded object-contain text-3xl"
          :class="{
            'text-action': step.enabled,
            'text-red-500': step.enabled && !step.completed,
            'animate-shake text-red-500': step.enabled && !step.completed && error
          }"
          :icon-name="step.value"
        />
        <div class="flex flex-grow items-center justify-between">
          <span
            class="transition-opacity duration-300"
            :class="{
              'text-action': step.enabled,
              'text-red-500': step.enabled && !step.completed
            }"
          >
            <template v-if="step.enabled">
              <AnimatedNumber :model-value="findServiceTotal(totals, step.totals_key)" />
            </template>
            <template v-else> {{ step.label }}</template>
          </span>
          <!-- Deactivate button -->
          <button
            v-if="step.enabled"
            class="text-sm text-white underline"
            @click.stop="() => deactivateStep(step)"
          >
            Entfernen
          </button>
          <button
            v-else
            class="rounded-full border px-2 text-xl text-gray-300"
            @click.stop="() => setActiveStep(step)"
          >
            +
          </button>
        </div>
      </li>
      <li
        class="relative flex cursor-pointer items-center gap-2 overflow-hidden whitespace-nowrap px-2 text-gray-300"
        :class="{ 'border-l-2': isActive(contactStep) }"
      >
        <div class="my-3 flex flex-grow items-center justify-between text-xl font-bold">
          <button
            data-track="dt_handwerker_finden"
            class="flex w-full items-center justify-start rounded-md px-2 py-2 text-black"
            @click="contactFormButton"
            :class="isSubmitReady ? 'bg-action' : 'bg-gray-300'"
          >
            <CheckIcon class="h-6 w-6 md:block" />
            <span class="">Jetzt Handwerker finden</span>
          </button>
        </div>
      </li>
    </ul>
  </nav>
</template>

<style scoped>
.sidebar {
  width: 80px;
  overflow: hidden;
}

.sidebar.expanded {
  width: 500px;
}

.sidebar:hover {
  width: 300px;
}
</style>
